import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import continisTheme from "@continis/theme";

Alpine.plugin(collapse);
Alpine.plugin(continisTheme);

// document.addEventListener('alpine:init', () => {
// });

Alpine.start();

window.htmx = require('htmx.org');
